import React from 'react';
import { Flex, Box, Heading } from 'rebass';
import { Helmet } from 'react-helmet';
import Header1 from '../../components/Commons/Headers/Header1';
import Layout from '../../components/Layout'
const PolicyPage = props => {
  const { title, body } = props.data.policies.nodes[0];
  // const { storeName } = props.data.store.siteMetadata.gatsbyStorefrontConfig;
  return (
    <Flex flexWrap="wrap" px={2} pt={3} mx="auto" style={{ maxWidth: 1300 }}>
      {/*<Helmet title={title} titleTemplate={`%s — ${storeName}`} defer={false}>
        <meta name="description" content={title} />
      </Helmet>*/}
        <Header1 title={title} />
        <div className="row">
        <div className="col-lg-10 offset-lg-1 policyBody" dangerouslySetInnerHTML={{ __html: body }}></div>
        </div>
    </Flex>
  );
};

export default PolicyPage;
